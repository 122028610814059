<template>
  <div>
    <header class="relative">
      <!-- Container for the Three.js canvas -->
      <div id="three-canvas">
        <!-- SVG Logo centered over the canvas -->
        <img src="/i/logo_w_slogan_black.min.svg" alt="A.I. Robotika" class="logo" />
      </div>
    </header>
  </div>
</template>

<script>
import * as THREE from 'three';
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js';
//import { UnrealBloomPass } from 'three/addons/postprocessing/UnrealBloomPass.js';
//import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';

export default {
  name: 'HeaderComponent',
  data() {
    return {
      nodes: [],
      lines: [],
    };
  },

  mounted() {
    this.initThreeJS();
    //this.scene.background = new THREE.Color(0x000000); 
    this.scene.background = new THREE.Color(0xffffff);
    this.setupFinalPass();
    this.animate();
    window.addEventListener('resize', this.onWindowResize);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
    // Additional cleanup like disposing of Three.js objects
  },
  methods: {
    initThreeJS() {
      // Scene, camera, and renderer setup...
      this.scene = new THREE.Scene();
      this.camera = new THREE.PerspectiveCamera(3, window.innerWidth / 300, 0.1, 1000);
      this.camera.position.z = 50;
      this.renderer = new THREE.WebGLRenderer({ antialias: true });

      this.vertexShader = `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `;
      this.fragmentShader = `
        uniform sampler2D baseTexture;
        uniform sampler2D bloomTexture;
        varying vec2 vUv;
        void main() {
          gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );
        }`;
      this.darkMaterial = new THREE.MeshBasicMaterial({ color: 'black' });
      this.nodeMaterial = new THREE.MeshBasicMaterial({ color: 0x003300 });
      this.lineMaterial = new THREE.LineBasicMaterial({ color: 0x003300 });
      this.firingLineMaterial = new THREE.LineBasicMaterial({ color: 0x00ff00 });
      // this.firingLineMaterial = new THREE.MeshStandardMaterial({
      //   color: 0x005500, // base color
      //   emissive: 0x00ff00, // emissive color
      //   emissiveIntensity: 2 // increase intensity to make it stand out
      // });
      this.firingNodeMaterial = new THREE.MeshStandardMaterial({
        color: 0x005500, // base color
        emissive: 0x00ff00, // emissive color
        emissiveIntensity: 2 // increase intensity to make it stand out
      });

      this.networkGroup = new THREE.Group();
      this.bloomLayer = new THREE.Layers();
      this.materials = {};  // Ensure materials is initialized as an empty object
      this.bloomComposer = null;
      this.finalComposer = null;
      // Bloom parameters
      this.bloomParams = {
        threshold: 0,
        strength: 0.5,
        radius: 0.5,
        exposure: 1
      }

      // Adjust the material colors for better visibility
      this.lineMaterial = new THREE.LineBasicMaterial({ color: 0x333333 }); // Darker color for lines

      // Ensure camera is positioned correctly
      this.camera.position.set(0, 0, 500); // Example position

      // Nodes and lines setup...
      this.setupNodesAndLines();

      // Bloom effect setup...
      this.setupBloomEffect();

      // Setup final pass and composer
      this.setupFinalPass();
      this.onWindowResize();
      document.getElementById('three-canvas').appendChild(this.renderer.domElement);

      // Start animation loop
      this.animate();
    },
    setupFinalPass() {
      if (!this.renderer) {
        console.error("Renderer not initialized");
        return;
      }

      const mixPass = new ShaderPass(
        new THREE.ShaderMaterial({
          uniforms: {
            baseTexture: { value: null },
            bloomTexture: { value: this.bloomComposer.renderTarget2.texture }
          },
          vertexShader: this.vertexShader,
          fragmentShader: this.fragmentShader,
          defines: {}
        }), 'baseTexture'
      );
      mixPass.needsSwap = true;

      //const outputPass = new OutputPass();

      // Using this.finalComposer instead of a local variable
      this.finalComposer = new EffectComposer(this.renderer);
      this.finalComposer.addPass(new RenderPass(this.scene, this.camera));
      //this.finalComposer.addPass(mixPass);
      //this.finalComposer.addPass(outputPass);

      // Set this flag to true if you want to render the final composer's result to the screen:
      this.finalComposer.renderToScreen = true;
    },
    setupNodesAndLines() {
      const numberOfLayers = 1;
      const nodesPerLayer = 50;

      //  // Set background to black
      // Add network group to the scene
      this.scene.add(this.networkGroup);

      this.bloomLayer.set(1); // Set to an arbitrary number like 1
      // Create nodes and lines
      for (let layer = 0; layer < numberOfLayers; layer++) {
        for (let i = 0; i < nodesPerLayer; i++) {
          const nodeGeometry = new THREE.SphereGeometry(0.1, 32, 32);
          const node = new THREE.Mesh(nodeGeometry, this.nodeMaterial);
          node.position.x = (Math.random() - 0.5) * 10;
          node.position.y = (Math.random() - 0.5) * 10;
          node.position.z = (Math.random() - 0.5) * 10;

          // Scale and position nodes more broadly
          node.scale.set(2, 2, 2); // Scale up the nodes
          node.position.multiplyScalar(2); // Spread the nodes further apart
          node.layers.enable(1); // Enable this layer on the node

          this.networkGroup.add(node);
          this.scene.add(node);
          this.nodes.push(node);
        }
      }

      // Create connections between all nodes
      this.nodes.forEach((node, index) => {
        const numberOfConnections = Math.floor(Math.random() * 5) + 1; // For example, 1 to 5 connections per node
        for (let i = 0; i < numberOfConnections; i++) {
          // Choose a random node to connect to
          let otherIndex = Math.floor(Math.random() * this.nodes.length);

          // Ensure we don't connect a node to itself
          while (otherIndex === index) {
            otherIndex = Math.floor(Math.random() * this.nodes.length);
          }

          const otherNode = this.nodes[otherIndex];
          const lineGeometry = new THREE.BufferGeometry().setFromPoints([node.position, otherNode.position]);
          const line = new THREE.Line(lineGeometry, this.lineMaterial);

          this.scene.add(line);
          //this.networkGroup.add(line);
          this.lines.push({ line, nodes: [node, otherNode] });
        }
      });
    },

    setupBloomEffect() {
      // Ensure that this.bloomComposer is assigned correctly
      const renderScene = new RenderPass(this.scene, this.camera);
      // const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, 300), 1.5, 0.4, 0.85);
      // bloomPass.threshold = this.bloomParams.threshold;
      // bloomPass.strength = this.bloomParams.strength;
      // bloomPass.radius = this.bloomParams.radius;

      this.bloomComposer = new EffectComposer(this.renderer);
      this.bloomComposer.renderToScreen = false;
      this.bloomComposer.addPass(renderScene);
      //this.bloomComposer.addPass(bloomPass);
    },
    updateNetwork() {
      // Update lines and fire nodes and their connections randomly
      this.lines.forEach((lineObject) => {
        const { line, nodes } = lineObject;

        if (Math.random() > 0.95) {
          nodes[0].material = this.firingNodeMaterial;
          nodes[1].material = this.firingNodeMaterial;
          line.material = this.firingLineMaterial;
        } else {
          nodes[0].material = this.nodeMaterial;
          nodes[1].material = this.nodeMaterial;
          line.material = this.lineMaterial;
        }
      });

      // Rotate the entire network
      this.networkGroup.rotation.x += 0.005;
      this.networkGroup.rotation.y += 0.005;
    },
    animate() {
      const animate = () => {
        const time = Date.now() * 0.0001;
        requestAnimationFrame(animate);
        this.updateNetwork();

        this.renderer.autoClear = false;
        this.renderer.clear();

        this.scene.traverse(this.darkenNonBloomed);
        this.bloomComposer.render();
        this.scene.traverse(this.restoreMaterial);

        // Rotate the camera around the network
        this.camera.position.x = Math.sin(time) * 60;
        this.camera.position.y = Math.cos(time) * 60;
        this.camera.position.z = Math.cos(time) * 60;

        this.camera.lookAt(this.scene.position);
        //this.renderer.render(this.scene, this.camera);
        this.finalComposer.render();
      };

      animate();
    },

    darkenNonBloomed(obj) {
      if (obj.isMesh && !obj.layers.test(this.bloomLayer)) {
        if (obj.material && obj.material !== this.darkMaterial) {
          this.materials[obj.uuid] = obj.material;
          obj.material = this.darkMaterial;
        }
      }
    },

    restoreMaterial(obj) {
      if (obj.uuid && this.materials[obj.uuid]) {
        obj.material = this.materials[obj.uuid];
        delete this.materials[obj.uuid];
      }
    },
    // Example of a resize event handler
    onWindowResize() {
      const width = window.innerWidth;
      const height = 300;

      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(width, height);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.toneMapping = THREE.ReinhardToneMapping;
      this.renderer.toneMappingExposure = Math.pow(this.bloomParams.exposure, 4.0);

      this.bloomComposer.setSize(width, height);
      this.finalComposer.setSize(width, height);
    },
    animateNetwork() {
      // Rotate the entire network as one group
      this.networkGroup.rotation.x += 0.01;
      this.networkGroup.rotation.y += 0.01;
      this.networkGroup.rotation.y += 0.01;

      // Update lines and fire nodes and their connections randomly
      this.lines.forEach((lineObject) => {
        const { line, nodes } = lineObject;

        if (Math.random() > 0.95) {
          // Fire the nodes and the line connecting them
          nodes[0].material = this.firingNodeMaterial;
          nodes[1].material = this.firingNodeMaterial;
          line.material = this.firingLineMaterial;
        } else {
          // Return to normal state
          nodes[0].material = this.nodeMaterial;
          nodes[1].material = this.nodeMaterial;
          line.material = this.lineMaterial;
        }
      });
    },
  },
};
</script>
<style scoped>
#three-canvas {
  width: 100vw;
  height: 300px;
  display: block;
  position: relative;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  height: calc(100% - 40px);
  /* Adjust the height as needed with margin */
  transform: translate(-50%, -50%);
}

.btn-nav {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  height: 3rem;
  /* Adjust the height to match the SVG logo */
  line-height: 3rem;
  /* Adjust line height for vertical centering */
  text-align: center;
  background-color: transparent;
  color: #000;
  text-decoration: none;
}

/* Tailwind utility classes for hover effects */
.btn-nav:hover {
  background-color: #f3f4f6;
  /* Light grey background on hover */
  color: #1f2937;
  /* Darker text color on hover */
}

/* Adjusting the logo and navigation button sizes for smaller screens */
@media (max-width: 640px) {
  .btn-nav {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    height: 2.5rem;
    line-height: 2.5rem;
  }
}
</style>
