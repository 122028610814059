<template>
  <!-- Fixed message box -->
  <div v-if="serverMessage" :class="{ 'alert-success': isSuccess, 'alert-error': !isSuccess }" class="fixed-message">
    {{ serverMessage }}
  </div>

  <div class="container mx-auto px-4 py-8">
    <!-- About Section -->
    <section class="my-8">
      <h2 class="text-4xl font-bold text-center font-orbitron mb-6">About Us</h2>
      <p class="text-lg font-roboto leading-relaxed">
        Pioneering the future of Artificial Intelligence and Robotics, our journey began with the vision to integrate
        advanced AI into everyday life, making technology more accessible and efficient.
      </p>
    </section>

    <!-- Our Approach Section -->
    <section class="my-8">
      <h3 class="text-3xl font-semibold font-orbitron mb-4">Our Approach</h3>
      <p class="text-lg font-roboto leading-relaxed">
        Embracing flexibility and innovation, we operate beyond traditional team structures. Each project features a
        handpicked team of experts, selected for their passion and unique skills in AI and robotics. This dynamic model
        keeps us agile and at the forefront of AI technological advancements.
      </p>
      <p class="text-lg font-roboto leading-relaxed mt-4">
        Our proficiency in generating high-grade synthetic data, coupled with mastery in 3D scanning, rendering, and
        automatic data labeling, is crucial for developing top-tier Computer Vision Models. Additionally, our lab focuses
        on utilizing reinforcement learning to craft intelligent, adaptive robotic systems, further enhancing our AI and
        robotics integration.
      </p>
    </section>

    <!-- AI for AI Section -->
    <section class="my-8">
      <h3 class="text-3xl font-semibold font-orbitron mb-4">AI for AI</h3>
      <p class="text-lg font-roboto leading-relaxed">
        Our innovative approach extends to employing Artificial Intelligence in creating AI systems. This meta-utilization
        of AI involves advanced agents for coding and implementation, resulting in self-evolving, cutting-edge solutions.
        This method significantly enhances efficiency and precision, opening new avenues in AI development.
      </p>
    </section>

    <!-- Our Mission Section -->
    <section class="my-8">
      <h3 class="text-3xl font-semibold font-orbitron mb-4">Our Mission</h3>
      <p class="text-lg font-roboto leading-relaxed">
        "Engineering Joyful Automation and Robotics: Leading AI Innovations for a Brighter Future." At the heart of our mission lies the commitment to pioneering advancements in AI and
        robotics. Our endeavors are dedicated to crafting a future where technology harmonizes with human aspirations,
        fostering an era of abundance and shared joy.
      </p>
    </section>

    <!-- Why Choose Us Section -->
    <section class="my-8">
      <h3 class="text-3xl font-semibold font-orbitron mb-4">Why Us?</h3>
      <p class="text-lg font-roboto leading-relaxed">
        Known for setting trends rather than following them, our unwavering dedication to excellence, innovation, and
        client satisfaction sets us apart in the technological landscape. We strive to exceed expectations, equipping our
        clients with the most advanced solutions in AI and robotics.
      </p>
    </section>

    <!-- Company Details Section -->
    <section class="my-8">
      <h2 class="text-3xl font-semibold font-orbitron mb-4">Company Details</h2>
      <div class="text-lg font-roboto leading-relaxed">
        <p><strong>A.I. Robotika Kft.</strong> - The joyful robotics</p>
        <p><strong>Address:</strong> 8808 Nagykanizsa, Diófa utca 2.</p>
        <p><strong>Tax Number:</strong> 13304452-2-20 (HU13304452)</p>
        <p><strong>Company Name:</strong> A.I. Robotika Kutató és Fejlesztő Korlátolt Felelősségű Társaság (A.I. Robotics Research and Development Limited Liability Company)</p>
        <p><strong>Headquarters:</strong> 8808 Nagykanizsa, Felsőerdő utca 63.</p>
        <p><strong>Location:</strong> 8808 Nagykanizsa, Diófa utca 2. (Hrsz.: 4291/9)</p>
        <p><strong>Registration Number:</strong> 20-09-066379</p>
        <p><strong>Founded:</strong> 2004</p>
        <p><strong>Bank Account:</strong> 16200247-10013900-00000000 (Magnet Bank)</p>
        <p><strong>IBAN:</strong> HU72162002471001390000000000</p>
        <!-- Add SWIFT code if available -->
        <p><strong>Industry Code (TEÁOR):</strong> 72.19 - Other natural science, technical research, development</p>
        <!-- If there's more relevant information, add it here -->
      </div>
    </section>

    <!-- Leadership Section -->
    <section class="my-8">
      <div class="flex flex-col md:flex-row items-top">
        <div class="flex-grow">
          <h3 class="text-3xl font-semibold font-orbitron mb-4">Leadership</h3>
          <p class="text-lg font-roboto leading-relaxed">
            Under the guidance of <span class="font-bold">István Csiszár</span>, our CEO and chief AI architect, our
            company has thrived, creating an environment that fosters creativity and excellence.
          </p>
        </div>
        <img v-lazy="'/i/profile/ceo.png'" alt="István Csiszár"
          class="w-full h-auto md:max-w-xs md:rounded-full shadow-lg">
      </div>
    </section>

    <button ref="contactButton" @click="showContactForm = true" class="btn-open-form text-blue-500 hover:text-blue-700">
      Contact Us
    </button>

    <!-- Contact Form Component -->
    <ContactComponent v-model:show="showContactForm" @form-success="handleFormSuccess"
      @form-failure="handleFormFailure" />
  </div>
</template>

<script>
import ContactComponent from './ContactComponent.vue';

export default {
  name: 'AboutComponent',
  components: {
    ContactComponent
  },
  data() {
    return {
      serverMessage: '',
      isSuccess: false,
      showContactForm: false
    };
  },
  methods: {
    handleFormSuccess(message) {
      this.serverMessage = message;
      this.isSuccess = true;
      this.showContactForm = false; // Add this line to hide the contact form
      this.fadeMessage();
    },

    handleFormFailure(message) {
      this.serverMessage = message;
      this.isSuccess = false;
      this.fadeMessage();
    },
    fadeMessage() {
      setTimeout(() => {
        this.serverMessage = '';
      }, 5000); // Display the message for 5 seconds
    },
  },
  metaInfo() {
    const structuredData = {
      "@context": "http://schema.org",
      "@type": "Organization",
      "name": "A.I. Robotika Kft.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Diófa utca 2",
        "addressLocality": "Nagykanizsa",
        "postalCode": "8808",
        "addressCountry": "HU"
      },
      "taxID": "13304452-2-20",
      // Include other relevant information...
    };
    return {
      title: 'About A.I. Robotika - Pioneering AI and Robotics',
      script: [
        {
          type: 'application/ld+json',
          json: structuredData
        }
      ],
      meta: [
        {
          name: 'description',
          content: 'Discover A.I. Robotika’s journey in pioneering Artificial Intelligence and Robotics. Learn about our innovative approach, mission, and the leadership driving us forward.'
        },
        {
          name: 'keywords',
          content: 'AI, artificial intelligence, robotics, AI innovation, AI solutions, AI technology, Robotika leadership, AI development'
        },
        // Other meta tags as needed
      ]
    };
  }
  // Any additional component logic goes here
};
</script>

<style scoped>
/* Existing styles for .fixed-message */
.fixed-message {
  position: fixed;
  top: 0;
  z-index: 20;
  left: 50%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Adjust for exact center alignment */
  padding: 1rem 2rem;
  /* Add padding around the text */
  margin: 0.5rem 0;
  /* Add some margin to the top and bottom */
  text-align: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  color: white;
  /* Ensure text is readable */
  background-color: #28a745;
  /* Default to success color, will be overridden by class */
  border: 1px solid #ddd;
  /* Faint border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Subtle drop shadow */
  max-width: 80%;
  /* Maximum width */
  white-space: nowrap;
  /* Prevents text wrapping */
  overflow: hidden;
  /* Ensures content doesn't spill out */
  text-overflow: ellipsis;
  /* Add ellipsis for overflowed text */
}

.alert-success {
  background-color: #28a745;
  border-color: #4cae4c;
  /* Lighter green for the border */
}

.alert-error {
  background-color: #dc3545;
  border-color: #d9534f;
  /* Lighter red for the border */
}

.fade-out {
  opacity: 0;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.font-orbitron {
  font-family: 'Orbitron', sans-serif;
}</style>
