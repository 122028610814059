/* eslint-disable */
<template>
  <div>
    <HeaderComponent />
    <NavigationComponent :alwaysShowLogo="true" />
    <AboutComponent />
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import NavigationComponent from '@/components/NavigationComponent.vue'; 
import AboutComponent from '@/components/AboutComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  name: 'AboutPage',
  components: {
    HeaderComponent,
    NavigationComponent,
    AboutComponent,
    FooterComponent
  },
  // Home page specific logic goes here
};
</script>
