<template>
  <div v-if="loading" class="lab-loading">
    Loading...
  </div>
  <div v-else-if="error" class="lab-error">
    {{ error }}
  </div>
  <div v-else class="lab-content">
    <div class="container mx-auto px-4 py-8">
      <section class="my-8">
        <h2 class="text-4xl font-bold text-center font-orbitron mb-6">{{ lab.title }}</h2>
      </section>
    </div>
    <div class="container mx-auto px-4 py-8">
      <section>
        <div class="lab-content-text text-lg font-roboto" v-html="convertMarkdownToHtml(lab.full_text)"></div>
        <div class="text-lg mt-4 font-roboto">
          <div v-if="Array.isArray(lab.remote_url)">
            <div v-for="(url, index) in lab.remote_url" :key="index">
              <a :href="url" target="_blank" class="flex items-center gap-2 text-blue-600 hover:text-blue-800 underline">
                Launch {{ lab.title }} (Link {{ index + 1 }})
                <font-awesome-icon icon="external-link-alt" /> <!-- Font Awesome external link icon -->
              </a>
            </div>
          </div>
          <div v-else-if="lab.remote_url">
            <a :href="lab.remote_url" target="_blank" class="flex items-center gap-2 text-blue-600 hover:text-blue-800 underline">
              Launch {{ lab.title }}
              <font-awesome-icon icon="external-link-alt" /> <!-- Font Awesome external link icon -->
            </a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { marked } from 'marked';

export default {
  data() {
    return {
      lab: {},
      loading: false,
      error: null,
    };
  },
  created() {
    this.fetchLabData();
  },
  methods: {
    convertMarkdownToHtml(markdown) {
      return marked.parse(markdown);
    },
    fetchLabData() {
      this.loading = true;
      this.error = null;
      const permalink = this.$route.params.permalink;

      console.log("Fetching data for permalink:", permalink); // Log the ID

      axios.get(`/api/labs/${permalink}`)
        .then(response => {
          this.lab = response.data; // Assign to this.lab instead of this.lab
        })
        .catch(error => {
          this.error = "An error occurred while fetching lab data.";
          console.error("Fetch error:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  metaInfo() {
    // Check if lab data is available
    if (this.lab && Object.keys(this.lab).length > 0) {
      return {
        title: `${this.lab.title} - In-depth Lab Details`,
        meta: [
          {
            name: 'description',
            content: `${this.lab.short_summary || 'Detailed insights into our lab focusing on cutting-edge research and development in ' + this.lab.title}.`
          },
          {
            name: 'keywords',
            content: `Lab, ${this.lab.title}, Research, Development, Science, Technology, Innovation`
            // Add more specific keywords related to the lab's content
          },
          // Other dynamic meta tags if necessary
        ]
      };
    }

    // Default meta tags when lab data is not loaded yet
    return {
      title: 'Loading Lab...',
      meta: [
        {
          name: 'description',
          content: 'Loading lab details. Explore our labs that focus on groundbreaking research and innovation.'
        },
        // Default meta tags...
      ]
    };
  },
};
</script>

<style scoped>
.lab-content-text ol {
  padding: 1rem 2rem;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.font-orbitron {
  font-family: 'Orbitron', sans-serif;
}

.lab-loading {
  padding: 20px;
  text-align: center;
  color: #666;
  font-size: 18px;
}

.lab-error {
  padding: 20px;
  text-align: center;
  color: #b00;
  font-size: 18px;
  background-color: #fee;
  border: 1px solid #fbb;
  border-radius: 8px;
}
</style>
