<template>
  <div ref="heroContainer" class="hero">
    <section class="px-4 pt-8 pb-4">
      <h1 class="text-5xl text-center font-semibold font-orbitron">The joyful robotics</h1>
      <p class="py-6 text-center text-2xl font-roboto">Innovative Solutions in AI and Robotics</p>
    </section>
  </div>

  <div class="container mx-auto px-4">
    <section class="my-8">
      <h2 class="text-3xl md:text-4xl font-orbitron font-semibold">Our Services</h2>
      <p class="text-lg mt-4 font-roboto">
        A.I. Robotika Kft. specializes in transformative <strong>Artificial Intelligence</strong> and
        <strong>Robotics</strong>
        solutions. We are committed to advancing technology through innovation and expertise.
      </p>
    </section>

    <section class="my-8">
      <h2 class="text-3xl md:text-4xl font-orbitron font-semibold">Expertise at A.I. Robotika</h2>
      <ul class="list-disc list-inside space-y-2 mt-4 text-lg font-roboto">
        <li>
          Advanced <strong>synthetic data generation</strong> including 3D scanning and rendering, crucial for training
          Computer Vision Models.
        </li>
        <li>
          Comprehensive services in <strong>data preparation</strong>: collecting, cleaning, merging, and labeling for
          supervised learning.
        </li>
        <li>
          Tailored <strong>AI agent solutions</strong> crafted to address a spectrum of business requirements.
        </li>
        <li>
          Expertise in <strong>developing AI models for IoT devices</strong>, including <strong>embedded machine
            learning</strong> and <strong>computer vision on microcontrollers</strong>. Enhanced smart functionality
          through advanced learning algorithms, efficient communication coding, and integration of protocols such as
          MQTT.
        </li>
      </ul>
    </section>
  </div>

  <footer class="text-center my-12">
    <p class="text-lg font-roboto">Discover how A.I. Robotika can elevate your technological potential.
    </p>
    <span class="link-block">
      <router-link to="/about-us" class="text-blue-500 hover:text-blue-700">Learn more about us</router-link>.
    </span>
  </footer>
</template>

<script>
/* eslint-disable */

import { heroAnimationMixin } from '@/mixins/heroAnimationMixin';
// import heroImage from '@/assets/hero.webp';
// import patternImage from '@/assets/pattern.png';

const structuredData = {
  "@context": "http://schema.org",
  "@type": "Organization",
  "name": "A.I. Robotika",
  "url": "https://www.airobotika.com",
  "logo": "https://www.airobotika.com/i/logo_w_slogan_black.svg",
  "description": "A.I. Robotika specializes in transformative Artificial Intelligence and Robotics solutions.",
  "founder": "Istvan Csiszar",
  "foundingDate": "2004-06-05",
  "location": "Europe",
  "sameAs": [
    // Social media or other profiles
    // "https://www.facebook.com/airobotika",
    //"https://www.linkedin.com/company/airobotika"
  ],
  "service": [
    {
      "@type": "Service",
      "name": "Synthetic Data Generation",
      "description": "Advanced synthetic data generation including 3D scanning and rendering, crucial for training Computer Vision Models."
    },
    {
      "@type": "Service",
      "name": "Data Preparation Services",
      "description": "Comprehensive services in data preparation: collecting, cleaning, merging, and labeling for supervised learning."
    },
    {
      "@type": "Service",
      "name": "Customized AI Agent Solutions",
      "description": "Customized AI agent solutions, specializing in Generative Pre-trained Transformers (GPTs) tailored for diverse business needs."
    }
    // Add other services as needed
  ]
};

export default {
  //mixins: [heroAnimationMixin],
  mounted() {
    this.$nextTick(() => {
      // If you need to do something else when the component mounts,
      // make sure to call `this.initThree()` as well.
      //this.initThree();
      // Any additional mounted logic...
    });
  },
  data() {
    return {
      // heroImageUrl: heroImage,
      // patternImageUrl: patternImage
    };
  },
  metaInfo() {
    return {
      title: 'A.I. Robotika - Pioneering AI and Robotics Solutions',
      script: [
        {
          type: 'application/ld+json',
          json: structuredData
        }
      ],
      meta: [
        {
          name: 'description',
          content: 'A.I. Robotika leads in innovative AI and Robotics solutions. Discover our transformative services in synthetic data generation, data preparation, and customized AI agents.'
        },
        {
          name: 'keywords',
          content: 'AI solutions, robotics, synthetic data, data preparation, AI agents, GPT, artificial intelligence, robotics technology'
        },
        // Other relevant meta tags...
        { property: 'og:title', content: 'A.I. Robotika - Pioneering AI and Robotics Solutions' },
        { property: 'og:description', content: 'Leading in AI and Robotics with transformative solutions. Explore our services in synthetic data generation, AI agent development, and more.' },
        // Include og:image, og:url, etc., as necessary
      ],
      // Similarly for Twitter Cards...
    };
  },
  // ...
};
</script>

<style scoped>
.hero-overlay {
  background-color: #000;
  /* Or any other color */
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.font-orbitron {
  font-family: 'Orbitron', sans-serif;
}
</style>
