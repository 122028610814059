<template>
  <HeaderComponent />
  <NavigationComponent :alwaysShowLogo="true" />
  <AIAgentsComponent />
  <FooterComponent />
</template>
  
<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import NavigationComponent from '@/components/NavigationComponent.vue';
import AIAgentsComponent from '@/components/AIAgentsComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  components: {
    HeaderComponent,
    NavigationComponent,
    AIAgentsComponent,
    FooterComponent
  },
};
</script>

<style scoped>
.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.font-orbitron {
  font-family: 'Orbitron', sans-serif;
}
</style>