<template>
  <HeaderComponent />
  <NavigationComponent :alwaysShowLogo="true" />
  <LabsComponent />
  <FooterComponent />
</template>
  
<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import NavigationComponent from '@/components/NavigationComponent.vue';
import LabsComponent from '@/components/LabsComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  components: {
    HeaderComponent,
    NavigationComponent,
    LabsComponent,
    FooterComponent
  },
  // Your page component's script
};
</script>

<style scoped>
.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.font-orbitron {
  font-family: 'Orbitron', sans-serif;
}
</style>