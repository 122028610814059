<template>
    <div class="container mx-auto p-6">
        <section class="my-8">
            <h2 class="text-4xl font-bold text-center font-orbitron mb-6">Labs</h2>
        </section>
        <!-- Filter Section -->
        <div class="mb-8">
            <input type="search" placeholder="Search labs..." class="p-2 border border-gray-300 rounded"
                v-model="searchTerm">
            <!-- Add dropdowns or other filter controls as needed -->
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div class="bg-white rounded-lg overflow-hidden shadow-lg p-4" v-for="lab in filteredlabs" :key="lab.id">
                <router-link :to="'/labs/' + lab.permalink" class="block hover:no-underline">
                    <img v-lazy="lab.image" :alt="lab.title" class="rounded w-full mb-4">
                    <h3 class="text-lg font-bold mb-2">{{ lab.title }}</h3>
                </router-link>
                <p class="text-gray-700 text-base mb-4">{{ lab.short_summary }}</p>
                <router-link :to="'/labs/' + lab.permalink" class="text-blue-500 hover:text-blue-700">Learn more</router-link>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';

export default {
    name: 'LabsComponent',
    data() {
        return {
            searchTerm: '',
            labs: [],  // Initialize as an empty array
        };
    },
    computed: {
        filteredlabs() {
            return this.labs.filter(lab => {
                return lab.title.toLowerCase().includes(this.searchTerm.toLowerCase());
            });
        },
    },
    methods: {
        fetchlabsData() {
            axios.get('/api/labs')  // Replace with your actual API endpoint
                .then(response => {
                    this.labs = response.data;
                })
                .catch(error => {
                    console.error('Error fetching labs data:', error);
                    // Handle error appropriately
                });
        }
    },
    mounted() {
        this.fetchlabsData();
    },
    metaInfo() {
        return {
            title: 'Explore Innovative Labs - Discover Cutting-Edge Research',
            meta: [
                {
                    name: 'description',
                    content: 'Dive into our diverse range of labs focusing on groundbreaking research and development in various scientific and technological fields.'
                },
                {
                    name: 'keywords',
                    content: 'scientific labs, research labs, technology development, innovative research, lab directory'
                },
                // Other meta tags...
            ]
        };
    },
};
</script>

<style scoped>
.font-roboto {
    font-family: 'Roboto', sans-serif;
}

.font-orbitron {
    font-family: 'Orbitron', sans-serif;
}
</style>