<template>
    <div class="container mx-auto p-6">
        <section class="my-8">
            <h2 class="text-4xl font-bold text-center font-orbitron mb-6">GPT Agents</h2>
        </section>

        <!-- Filter Section -->
        <div class="mb-8">
            <input type="search" placeholder="Search GPTs..." class="p-2 border border-gray-300 rounded"
                v-model="searchTerm">
            <!-- Add dropdowns or other filter controls as needed -->
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            <div class="bg-white rounded-lg overflow-hidden shadow-lg p-4" v-for="gpt in filteredGPTs" :key="gpt.id">
                <router-link :to="'/ai-agents/custom-gpts/' + gpt.permalink" class="block hover:no-underline">
                    <img v-lazy="gpt.image" alt="{{ gpt.title }}" class="rounded w-full mb-4">
                    <h3 class="text-lg font-bold mb-2">{{ gpt.title }}</h3>
                </router-link>
                <p class="text-gray-700 text-base mb-4">{{ gpt.short_summary }}</p>
                <router-link :to="'/ai-agents/custom-gpts/' + gpt.permalink" class="text-blue-500 hover:text-blue-700">Learn
                    more</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'GPTsGridComponent',
    data() {
        return {
            searchTerm: '',
            gpts: [],  // Initialize as an empty array
        };
    },
    computed: {
        filteredGPTs() {
            return this.gpts.filter(gpt => {
                return gpt.title.toLowerCase().includes(this.searchTerm.toLowerCase());
            });
        },
    },
    methods: {
        fetchGPTsData() {
            axios.get('/api/custom-gpts')  // Replace with your actual API endpoint
                .then(response => {
                    this.gpts = response.data;
                })
                .catch(error => {
                    console.error('Error fetching GPTs data:', error);
                    // Handle error appropriately
                });
        }
    },
    mounted() {
        this.fetchGPTsData();
    },
    metaInfo() {
        return {
            title: 'Explore Custom GPTs - Innovative AI Solutions',
            meta: [
                {
                    name: 'description',
                    content: 'Browse our diverse range of Custom GPTs tailored for various industries. Discover innovative AI solutions designed to streamline processes and enhance efficiency.'
                },
                {
                    name: 'keywords',
                    content: 'custom GPTs, AI solutions, tailored AI, industry-specific GPTs, innovative AI, artificial intelligence, GPT technology'
                },
                // Other meta tags as needed
            ]
        };
    },
};
</script>

<style scoped>
.font-roboto {
    font-family: 'Roboto', sans-serif;
}

.font-orbitron {
    font-family: 'Orbitron', sans-serif;
}
</style>