import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import './assets/styles.css'; // Keep your existing import
import { library } from '@fortawesome/fontawesome-svg-core';
import { faRobot, faTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { createMetaManager } from 'vue-meta';
import VueLazyload from 'vue3-lazy';

library.add(faExternalLinkAlt);
library.add(faRobot);
library.add(faTimes);

const app = createApp(App);

// Import your placeholder and error images
import loadingImage from '@/assets/loading.svg';
import errorImage from '@/assets/error.svg';

app.use(VueLazyload, {
  // Set the loading and error images
  loading: loadingImage,
  error: errorImage,
});
app.use(createMetaManager()); 
app.config.globalProperties.$http = axios;
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(VueReCaptcha, {
    siteKey: '6LcYaVMpAAAAAIbwBZKnDX7OWwUrj3SAHvV_9zlR', 
    loaderOptions: {
        autoHideBadge: true
    }
});
app.use(router).mount('#app');
