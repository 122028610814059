// heroAnimationMixin.js
import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

export const heroAnimationMixin = {
  data() {
    return {
      renderer: null,
      scene: null,
      camera: null,
      composer: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initThree();
      this.animate();
    });
  },
  beforeDestroy() {
    // Clean up and dispose of Three.js objects to prevent memory leaks
    this.scene.clear();
    this.renderer.dispose();
    this.composer.dispose();
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  },
  methods: {
    initThree() {
      // Scene
      this.scene = new THREE.Scene();

      // Camera
      this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      this.camera.position.z = 5;

      // Renderer
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      //this.$refs.heroContainer.appendChild(this.renderer.domElement);

      // Composer for postprocessing (like bloom)
      this.composer = new EffectComposer(this.renderer);
      const renderPass = new RenderPass(this.scene, this.camera);
      this.composer.addPass(renderPass);

      const bloomPass = new UnrealBloomPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        1.5, // intensity
        0.4, // radius
        0.85 // threshold
      );
      this.composer.addPass(bloomPass);

      // Handle window resizing
      window.addEventListener('resize', this.onWindowResize, false);
    },
    onWindowResize() {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.composer.setSize(window.innerWidth, window.innerHeight);
    },
    animate() {
      // This function will call itself on the next screen repaint
      this.animationFrameId = requestAnimationFrame(this.animate);

      // Here is where you would update any animation before rendering

      // Render the scene
      this.composer.render();
    },
  },
};
