<template>
  <HeaderComponent />
  <NavigationComponent :alwaysShowLogo="true" />
  <LabComponent />
  <FooterComponent />
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import NavigationComponent from '@/components/NavigationComponent.vue'; 
import LabComponent from '@/components/LabComponent.vue'; 
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  components: {
    HeaderComponent,
    NavigationComponent,
    LabComponent,
    FooterComponent
  }
};
</script>
