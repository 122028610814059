<template>
  <div v-if="loading" class="gpt-loading">
    Loading...
  </div>
  <div v-else-if="error" class="gpt-error">
    {{ error }}
  </div>
  <div v-else class="gpt-content">
    <div class="container mx-auto px-4 py-8">
      <section class="my-8">
        <h2 class="text-4xl font-bold text-center font-orbitron mb-6">{{ gpt.title }}</h2>
      </section>
    </div>
    <div class="container mx-auto px-4 py-8">
      <section>
        <div class="gpt-content-text text-lg font-roboto" v-html="convertMarkdownToHtml(gpt.full_text)"></div>
        <div class="text-lg mt-4 font-roboto">
          <a v-if="gpt.remote_url" :href="gpt.remote_url" target="_blank"
            class="flex items-center gap-2 text-blue-600 hover:text-blue-800 underline">
            Launch {{ gpt.title }}
            <font-awesome-icon icon="external-link-alt" /> <!-- Font Awesome external link icon -->
          </a>
        </div>
      </section>
      <!-- Links Section -->
      <div v-if="gpt.links && gpt.links.length" class="container mx-auto px-4 py-8">
        <section class="my-8">
          <h3 class="text-3xl font-semibold font-orbitron mb-4">Related Links</h3>
          <ul class="list-disc pl-5">
            <li v-for="(link, index) in gpt.links" :key="index" class="mb-2">
              <a :href="link"
                class="text-blue-600 hover:text-blue-800 visited:text-purple-600 transition-colors duration-300 ease-in-out"
                target="_blank">
                <font-awesome-icon icon="link" class="mr-2" /> <!-- Font Awesome link icon -->
                {{ link }}
              </a>
            </li>
          </ul>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { marked } from 'marked';

export default {
  data() {
    return {
      gpt: {
      },
      loading: false,
      error: null,
    };
  },
  created() {
    this.fetchLabData();
  },
  methods: {
    convertMarkdownToHtml(markdown) {
      return marked.parse(markdown);
    },
    fetchLabData() {
      this.loading = true;
      this.error = null;
      const permalink = this.$route.params.permalink;

      console.log("Fetching data for permalink:", permalink); // Log the ID

      axios.get(`/api/custom-gpts/${permalink}`)
        .then(response => {
          this.gpt = response.data; // Assign to this.gpt instead of this.lab
        })
        .catch(error => {
          this.error = "An error occurred while fetching gpt data.";
          console.error("Fetch error:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  metaInfo() {
    // Ensure gpt data is loaded before setting meta tags
    if (!this.gpt || Object.keys(this.gpt).length === 0) {
      return {
        title: 'Loading GPT...',
        meta: [
          { name: 'description', content: 'Loading GPT content...' },
          // Default meta tags...
        ]
      };
    }

    return {
      title: `${this.gpt.title} - Custom GPT Detail`,
      meta: [
        {
          name: 'description',
          content: `Learn more about ${this.gpt.title}: ${this.gpt.short_summary}`
        },
        {
          name: 'keywords',
          content: `GPT, ${this.gpt.title}, AI, Machine Learning, Custom AI Solutions`
          // Include other relevant keywords
        },
        // Additional dynamic meta tags...
      ]
    };
  }

};
</script>

<style scoped>
.gpt-content-text ol {
  padding: 1rem 2rem;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.font-orbitron {
  font-family: 'Orbitron', sans-serif;
}

.gpt-loading {
  padding: 20px;
  text-align: center;
  color: #666;
  font-size: 18px;
}

.gpt-error {
  padding: 20px;
  text-align: center;
  color: #b00;
  font-size: 18px;
  background-color: #fee;
  border: 1px solid #fbb;
  border-radius: 8px;
}


.list-disc {
  /* Style for bulleted list */
}

a {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

a:hover {
  text-decoration: none; /* Remove underline on hover */
}

a:visited {
  /* Style for visited links */
}

.font-awesome-icon {
  width: 16px;
  height: 16px;
}

</style>
