<template>
    <footer class="bg-gray-800 text-white py-8">
      <div class="container mx-auto px-4">
        <div class="footer-top flex flex-wrap justify-between items-center">
          <div class="footer-vision mb-4 md:mb-0">
            <p class="text-sm md:text-base font-light italic">
              "Engineering Joyful Automation and Robotics: Leading AI Innovations for a Brighter Future."
            </p>
          </div>
          <!-- <div class="footer-contact mb-4 md:mb-0">
            <router-link to="/about-us#contact" class="text-blue-400 hover:text-blue-300">
              Contact Us
            </router-link>
          </div> -->
        </div>
        <div class="footer-bottom mt-4 border-t border-gray-700 pt-4">
          <ul class="flex flex-wrap justify-center md:justify-start gap-4">
            <li><router-link to="/about-us" class="hover:underline">About Us</router-link></li>
            <li><router-link to="/ai-agents" class="hover:underline">AI Agents</router-link></li>
            <li><router-link to="/labs" class="hover:underline">Labs</router-link></li>
          </ul>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent',
    // rest of the component logic
  };
  </script>
  
  <style scoped>
  /* You can add more styling here if needed, 
     but Tailwind CSS should handle most of the styling */
  </style>
  