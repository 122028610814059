import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import AboutView from '@/views/AboutView.vue';
import AIAgentView from '@/views/AIAgentView.vue';
import GPTsView from '@/views/GPTsView.vue';
import GPTView from '@/views/GPTView.vue';
import LabsView from '@/views/LabsView.vue';
import LabView from '@/views/LabView.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/about-us',
    name: 'About',
    component: AboutView
  },
  {
    path: '/ai-agents',
    name: 'AI Agents',
    component: AIAgentView
  },
  {
    path: '/ai-agents/custom-gpts',
    name: 'Custom GPTs',
    component: GPTsView
  },
  {
    path: '/ai-agents/custom-gpts/:permalink',
    name: 'CustomGpt',
    component: GPTView
  },
  {
    path: '/labs',
    name: 'Labs',
    component: LabsView
  },
  {
    path: '/labs/:permalink',
    name: 'Lab',
    component: LabView
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

export default router;
