<template>
  <div class="fixed top-0 left-0 p-4 flex items-center space-x-4 z-10">
    <!-- Logo button: always show if 'alwaysShowLogo' prop is true -->
    <transition name="fade-slide">
      <button v-if="alwaysShowLogo || showLogo" class="nav-btn flex items-center" title="Home" @click="goHome">
        <img src="/i/logo.min.svg" alt="A.I. Robotika" class="h-4" />
      </button>
    </transition>
    <router-link to="/about-us" class="nav-btn">About Us</router-link>
    <router-link to="/ai-agents" class="nav-btn">AI Agents</router-link>
    <router-link to="/labs" class="nav-btn">Labs</router-link>
  </div>
</template>

<script>
export default {
  props: {
    alwaysShowLogo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showLogo: false,
    };
  },
  mounted() {
    if (!this.alwaysShowLogo) {
      window.addEventListener('scroll', this.handleScroll);
      this.handleScroll(); // Initialize the visibility on load
    }
  },
  beforeUnmount() {
    if (!this.alwaysShowLogo) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    handleScroll() {
      const canvasElement = document.getElementById('three-canvas');
      if (canvasElement) {
        const canvasBottom = canvasElement.getBoundingClientRect().bottom;
        this.showLogo = canvasBottom < 0; // Show logo if canvas is out of view
      }
    },
    goHome() {
      if (this.$route.path === '/') {
        // If already on the home page, scroll to the top
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Optional: Add smooth scrolling
        });
      } else {
        // Navigate to home page if on a different page
        this.$router.push('/');
      }
    },
  },
};
</script>


<style scoped>
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.fade-slide-leave-active {
  /* Active state for leave transition */
  position: absolute;
  left: 0;
}

/* Existing styles */
.nav-btn {
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  /* Keep the initial height */
  padding: 0.5rem 1rem;
  border: 2px solid #f3f3f3;
  border-radius: 0.2rem;
  transition: all 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 640px) {

  /* Adjust the breakpoint as needed */
  .nav-btn {
    height: 2rem;
    /* Smaller height on mobile */
    padding: 0.25rem 0.5rem;
    /* Smaller padding on mobile */
    font-size: 0.875rem;
    /* Smaller font size on mobile */
  }

  .nav-btn img {
    height: 1.5rem;
    /* Smaller logo size on mobile */
  }
}

.nav-btn:hover {
  background-color:#f3f3f3;
  border-color:#666;
}


/* Responsive adjustments for the logo */
@media (max-width: 640px) {

  /* Adjust the breakpoint as needed */
  .nav-btn img {
    height: auto;
    /* Maintain aspect ratio */
    width: 1.5rem;
    /* Adjust the width as needed */
  }
}
</style>
  