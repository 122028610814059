<template>
  <div v-if="show" class="fixed inset-0 flex items-center justify-center z-40">
    <!-- Overlay -->
    <div class="absolute inset-0 bg-black bg-opacity-50" @click="closeForm"></div>
    <!-- Contact Form Container -->
    <div
      class="relative bg-white p-8 w-full md:w-1/3 h-full md:transform md:translate-x-full transition-transform duration-300">
      <!-- Close Button -->
      <button
        class="absolute top-0 right-0 m-4 bg-transparent rounded-full w-8 h-8 flex items-center justify-center hover:bg-blue-700 transition-colors"
        @click="closeForm">
        <font-awesome-icon icon="times" class="text-black hover:text-white" />
      </button>

      <!-- Form Content -->
      <h2 class="text-2xl font-bold mb-4">What's on your mind?</h2>
      <p>We'll get back to you as soon as possible.</p>
      <form @submit.prevent="submitForm" class="space-y-4 py-4">
        <div class="grid grid-cols-2 gap-4">
          <input type="text" v-model="formState.firstName" placeholder="First Name*" class="w-full input-field" required>
          <input type="text" v-model="formState.lastName" placeholder="Last Name*" class="w-full input-field" required>
        </div>
        <input type="email" v-model="formState.email" placeholder="Email*" class="w-full input-field" required>
        <input type="text" v-model="formState.subject" placeholder="Subject*" class="w-full input-field" required>
        <textarea placeholder="Message*" v-model="formState.message" rows="5" class="w-full input-field"
          required></textarea>
        <button type="submit" data-sitekey="6LdvfFUpAAAAAIfEVlKyclE4rhZCiYI2QcYNljjN" data-callback="onSubmit"
          class="g-recaptcha w-full mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors">
          Submit</button>
      </form>
      <p class="text-sm">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy"
          target="_blank" class="text-blue-500">Privacy
          Policy</a> and <a href="https://policies.google.com/terms" target="_blank" class="text-blue-500">Terms of
          Service</a> apply.</p>
    </div>
  </div>
</template>
  
<script>
import { ref, reactive } from 'vue';
import { useReCaptcha } from 'vue-recaptcha-v3';
import axios from 'axios'; // Import Axios

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const serverMessage = ref(''); // Define serverMessage as a ref
    const isSuccess = ref(false); // Track if the submission was successful

    const recaptcha = useReCaptcha();
    if (!recaptcha) {
      console.error('reCAPTCHA has not been initialized properly');
      return;
    }

    const { executeRecaptcha } = useReCaptcha();
    const formState = reactive({
      firstName: '',
      lastName: '',
      email: '',
      subject: '',
      message: '',
      isSuccess: false,
    });

    const closeForm = () => {
      emit('update:show', false);
    };

    const submitForm = async () => {
      try {
        const token = await executeRecaptcha('submit');
        const formData = new FormData();
        formData.append('firstName', formState.firstName);
        formData.append('lastName', formState.lastName);
        formData.append('email', formState.email);
        formData.append('subject', formState.subject);
        formData.append('message', formState.message);
        formData.append('recaptcha', token);

        try {
          const response = await axios.post('/send-email', {
            firstName: formState.firstName,
            lastName: formState.lastName,
            email: formState.email,
            subject: formState.subject,
            message: formState.message,
            recaptcha: token
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });

          if (response.data.message) {
            // Clear the form
            Object.keys(formState).forEach(key => {
              formState[key] = '';
            });
            // Emit success message and close form
            emit('form-success', response.data.message);
            closeForm(); // Close the form
          } else if (response.data.error) {
            // Emit failure message
            emit('form-failure', response.data.error);
          }
        } catch (error) {
          emit('form-failure', 'An error occurred while sending the email.');
          isSuccess.value = false; // Set success to false on error
        }
      } catch (error) {
        emit('form-failure', 'An error occurred while sending the email.');
        isSuccess.value = false; // Set success to false on error
        console.error('Error sending email:', error);
      }
    };

    return { formState, closeForm, submitForm, serverMessage, isSuccess };
  },
  watch: {
    show(val) {
      if (val) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },
  },
  emits: ['update:show'],
};
</script>
  
<style scoped>
.input-field {
  padding: 0.5rem;
  border: 2px solid #DDD;
  border-radius: 0.375rem;
  /* This is 6px when using 'px' */
}

/* Slide-in transition */
.slide-in-enter-active,
.slide-in-leave-active {
  transition: transform 0.3s ease-out;
}

.slide-in-enter-from,
.slide-in-leave-to {
  transform: translateX(100%);
}
</style>
  