/* eslint-disable */
<template>
  <HeaderComponent />
  <NavigationComponent :alwaysShowLogo="false" />
  <ServicesComponent />
  <FooterComponent />
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import NavigationComponent from '@/components/NavigationComponent.vue';
import ServicesComponent from '@/components/ServicesComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  name: 'HomePage',
  components: {
    HeaderComponent,
    NavigationComponent,
    ServicesComponent,
    FooterComponent,
  },
  data() {
    return {
    };
  }
};
</script>
