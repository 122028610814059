<template>
    <div class="container mx-auto p-6">
        <section class="my-8">
            <h2 class="text-4xl font-bold text-center font-orbitron mb-6">We make AI Agents</h2>
        </section>
        <section class="my-8">
            <div>
                <p class="text-lg mt-4 font-roboto">AI agents are designed to perform tasks that range from simple to
                    complex, with the aim of enhancing efficiency, accuracy, and convenience across various domains.</p>
                <ul class="list-disc list-inside space-y-2 mt-4 text-lg font-roboto">
                    <li><strong>Automation</strong>: AI agents automate repetitive tasks, saving time and reducing human
                        error.</li>
                    <li><strong>Decision Making</strong>: AI agents analyze data and make decisions based on predefined
                        rules or learned patterns.</li>
                    <li><strong>Personalization</strong>: AI agents personalize user experiences by analyzing user
                        behavior and preferences.</li>
                    <li><strong>Prediction</strong>: AI agents can forecast future outcomes based on historical data.
                    </li>
                    <li><strong>Optimization</strong>: AI agents optimize processes to improve efficiency and
                        performance.</li>
                    <li><strong>Assistance</strong>: AI agents provide assistance to users by answering questions,
                        providing information, or guiding users through tasks. <router-link to="/ai-agents/custom-gpts" class="btn-open-form text-blue-500 hover:text-blue-700">Take a look at our public GPT Agents</router-link>.</li>
                    <li><strong>Learning and Adaptation</strong>: AI agents can learn from data and adapt their behavior
                        accordingly.</li>
                    <li><strong>Exploration</strong>: AI agents are used in exploration tasks where it may be dangerous
                        or impractical for humans to go.</li>
                </ul>
                <p class="text-lg mt-4 font-roboto">We specialize in empowering your company with cutting-edge AI agent development, whether they're embodied in robotic hardware or powered by innovative software solutions. Learn more about our expertise on our  <router-link to="/about-us" class="btn-open-form text-blue-500 hover:text-blue-700">About page</router-link>.</p>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'AIAgentsComponent',
    data() {
        return {
        };
    },
    computed: {
    },
    methods: {
    },
    mounted() {
    },
    metaInfo() {
        return {
            title: 'Learn About AI Agents - Innovative AI Solutions',
            meta: [
                {
                    name: 'description',
                    content: 'Learn about the purposes of AI agents and how they can benefit your business. We offer assistance in developing both robotic and software AI agents.'
                },
                {
                    name: 'keywords',
                    content: 'AI agents, robotic AI, software AI, automation, decision making, personalization, prediction, optimization, assistance, learning and adaptation, exploration'
                }
            ]
        };
    },
};
</script>

<style scoped>
.font-roboto {
    font-family: 'Roboto', sans-serif;
}

.font-orbitron {
    font-family: 'Orbitron', sans-serif;
}
</style>