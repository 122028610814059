<template>
  <HeaderComponent />
  <NavigationComponent :alwaysShowLogo="true" />
  <GPTComponent />
  <FooterComponent />
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import NavigationComponent from '@/components/NavigationComponent.vue'; 
import GPTComponent from '@/components/GPTComponent.vue'; 
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  components: {
    HeaderComponent,
    NavigationComponent,
    GPTComponent,
    FooterComponent
  }
};
</script>
